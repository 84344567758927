<template>
  <div id="building-floors">
    <FloorDialog :showDialog="isDialogVisible" :floorData="floorData" :building="building" :isEditing="isEditing" @close="toggleDialog()"/>
    <v-select
            id="ground-floor"
            label="Přízemní podlaží"
            color="light-green darken-1"
            class="no-padding"
            :value="building.defaultFloor"
            :items='floorsSelectData'
            @change="onChange"
            :rules="floorsSelectData.length > 0 ? [required_rule] : []"
    ></v-select>
    <div v-for="(floor, key) in floors" :key="floor.id || floorData.ghostId" class="my-3">
      <div class="d-flex align-center mb-2">
        <div class="mr-2">
          <b class="grey--text mr-3">{{floor.level}}:</b>
          <span class="floor-name">{{ getLocalizedFloor(floor) }}</span>
        </div>
        <EditIcon @click="edit(key)" class="edit-floor-btn" />
        <span class="missing-alert" v-if="!isFloorValid(floor)">Chybí překlad!</span>
        <v-spacer/>
        <DeleteIcon @click="deleteSingleFloor(floor)" />
      </div>
      <v-divider/>
    </div>
    <v-btn @click="prepareAddFloor()" id="add-floor-btn">+ Přidat podlaží</v-btn>
  </div>
</template>


<script>
    import { mapActions, mapGetters } from "vuex"
    import FloorDialog from "./FloorDialog"
    import EditIcon from "@/components/buttons/EditIcon"
    import DeleteIcon from "@/components/buttons/DeleteIcon"
    import { getLocalizedLanguages } from "@/localization/languages";


    export default {
        name: 'BuildingFloors',
        components: {
          FloorDialog,
          EditIcon,
          DeleteIcon,
        },

        props: {
            building: Object,
            curLng: String,
        },

        data() {
          return {
            required_rule: value => !!value || 'Povinné',
            isDialogVisible: false,
            isEditing: false,
            emptyFloor: {
              'level': null,
              'buildingId': this.building.id,
              'name': "",
            },
            floorData: {
              'level': 0,
              'buildingId': this.building.id,
              'name': "",
            },
          }
        },

        computed: {
          ...mapGetters('leavePrevent', ['hasUnsavedChanges']),
          ...mapGetters('floors', ['getBuildingFloors', 'isFloorValid', 'getSingleFloor']),

            isLanguageSet() {
                return this.building.language !== '';
            },

            floors() {
                return this.getBuildingFloors(this.building.id);
            },

            floorsSelectData(){
                return this.floors.map(floor => ({
                  text: this.getLocalizedFloor(floor),
                  //If it is new building we don't know the floor id -> we use ghost ids instead of ids
                  value: this.building.id !== undefined ? floor.id : floor.ghostId,
                }));
            },
        },

        methods: {
          ...mapActions('leavePrevent', ['madeUnsavedChanges']),
          ...mapActions('floors', ['removeGhostFloor', 'deleteFloor']),

            onChange(value) {
                this.$emit('input', value);
            },

            getLocalizedFloor(floor) {
              const floorName = floor.localizedAttributes[this.curLng];
              return floorName ? floorName.name : 'Chybí překlad';
            },

            languages() {
              const langs = getLocalizedLanguages();
              const langKeys = [];
              Object.keys(langs).map((lng) => {
                  langKeys.push(lng);
              });
              return langKeys;
            },

            prepareAddFloor() {
              this.floorData = Object.assign({}, this.emptyFloor);
              this.toggleDialog();
            },

            toggleDialog() {
              this.isDialogVisible = !this.isDialogVisible;
              this.isEditing = false;
              this.floorData = Object.assign({}, this.emptyFloor);
            },

            edit( index ) {
              const floor = this.floors[index];
              const localization = floor.localizedAttributes[this.curLng];

              this.floorData =  this._.cloneDeep(floor);
              this.floorData.name = localization ? localization["name"] : null;
              this.isDialogVisible = true;
              this.isEditing = true;
              this.madeUnsavedChanges();
            },

            deleteSingleFloor( floor ) {
              if (this.hasUnsavedChanges) {
                alert('Nejprve uložte změny v budově. Poté bude možné smazat podlaží.');
                return;
              }

              if (this.building.defaultFloor == floor.id) {
                alert('Nejprve změnte přízemní podlaží budovy. Poté půjde toto podlaží smazat.');
                return;
              }

              if (window.confirm("Opravdu smazat podlaží " + floor.localizedAttributes['cz']['name'] + "? Smažou se tím i všechna místa na daném podlaží!")) {
                if (floor.id !== undefined) {
                  this.deleteFloor(floor.id);
                  if (this.building.defaultFloor == floor.id){
                    this.building.defaultFloor = null;
                  }
                  this.$emit('onRemoveFloor', floor.id);
                } else {
                  this.removeGhostFloor(floor);
                  if (this.building.defaultFloor == floor.ghostId){
                    this.building.defaultFloor = null;
                  }
                }
              }
            }
        },
    }
</script>

<style scoped>
.small-warn {
  padding: 2px;
}

.no-padding {
  margin: 0px;
  padding: 0px;
}

.missing-alert {
  background-color: #fb8c00;
  color: white;
  border-radius: 3px;
  padding: 5px 10px;
}
</style>
