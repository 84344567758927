<template>
    <v-dialog v-model="showDialog" max-width="600px" persistent>
        <v-form @submit.prevent="save" ref="form" v-model="floor_valid">
            <v-card>
                <v-card-title>
                    <span v-if="!isEditing" class="headline">Nové podlaží</span>
                    <span v-else class="headline">Úprava podlaží</span>

                </v-card-title>
                <v-card-text class="row">
                    <v-text-field
                      class="col-md-6"
                      v-model.number="floorData.level"
                      type="number"
                      label="Číslo podlaží"
                      :rules="[integer_rule, unique_floor_number]"
                      id="floor-level"
                    />

                    <div class="col-md-12" v-for="(level, index) in currentLevelTranslations" :key="index">
                      <v-text-field
                              :rules="[required_rule, unique_floor_name]"
                              :id="`floor-name-`+index"
                              color="dark"
                              class="no-padding"
                              v-model.trim="level.name"
                              :label="'Název podlaží (' + index + ')'" 
                      />
                    </div>



                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="dark" text @click="close">Zavřít</v-btn>
                    <v-btn color="primary" text type="submit" :disabled="!floor_valid" id="save-floor-btn">Uložit</v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
    import { sanitize } from "@/utils";
    import { localizedAttributesFactory } from "@/localization/languages";

    export default {
        name: 'FloorDialog',

        props: {
            showDialog: Boolean,
            isEditing: Boolean,
            floorData: Object,
            building: Object,
        },

        data() {
          return {
            floor_valid: true,
            required_rule: value => (!!value && value.trim() !== "") || 'Povinné',
            integer_rule: value => !!String(value).match(/^-?[0-9]+$/) || 'Musí být celé číslo, např.: 2',
            unique_floor_name: value => {
                let names = this.getSanatizedFloorLocalizations(this.building.id, this.floorData.id || this.floorData.ghostId );
                return !names.includes(value !== null ? sanitize(value.trim()) : '') || 'Název již existuje'},
            unique_floor_number: value => {
                const levels = this.getBuildingFloorsExceptOne(this.floorData).map(f => f.level);
                return !levels.includes(value) || 'Podlaží s tímto číslem již existuje';},
          }
        },
        computed: {
          ...mapGetters('floors', ['getFloors', 'getSanatizedFloorLocalizations', 'getBuildingFloors', 'getBuildingFloorsExceptOne' ]),
          
          isNew(){
            return this.floorData.id === undefined;
          },

          currentLevelTranslations() {
              return this.floorData.localizedAttributes || localizedAttributesFactory({ name: null });
          },

        },

        methods: {
          ...mapActions('leavePrevent', ['madeUnsavedChanges']),
          ...mapActions('floors', ['createGhostFloor', 'updateGhostFloor', 'updateFloor', 'createFloor']),
          ...mapActions('buildings', ['updateBuilding']),

            onChange(value) {
                this.$emit('input', value);
            },

            close(){
                this.$emit('close');
            },

            save(){
                const floorWithLanguage = {
                  id: this.floorData.id,
                  level: this.floorData.level,
                  buildingId: this.floorData.buildingId,
                  localizedAttributes: this.currentLevelTranslations || {},
                  ghostId: this.floorData.ghostId || null
                };
                this.madeUnsavedChanges();

                if ( this.building.id !== undefined) {
                    if (this.isEditing) {
                      this.updateFloor(floorWithLanguage).then(() =>
                        this.close()
                      );
                    } else {
                      this.createFloor(floorWithLanguage).then(() =>
                        this.close()
                      );
                    }
                } else {
                    //if its new building we create ghost floor and update it once we have building id from BE
                    //update is handled in building detail, prompted by hasUnsavedFloors set to -> true
                    if(this.isEditing) {
                      this.updateGhostFloor(floorWithLanguage).then(this.close());
                    } else {
                      this.createGhostFloor(floorWithLanguage).then(this.close());
                    }
                }
            },
        },
    }
</script>

<style scoped>
.no-padding {
  margin: 0px;
  padding: 0px;
}

.hint {
  font-size: 12px;
  color: #b7b7b7;
}
</style>
