<template>
  <ItemNotFoundState v-if="objectNotFound" :objectName="'Budova'">
        <template #backButton>
            <BackButton :target="{name: 'map', params: {'tab': 'buildings'}}">Budovy</BackButton>
        </template>
  </ItemNotFoundState>
  <Loading v-else-if="singleDetail === null"/>
  <div v-else>
    <div class="header">
        <BackButton :target="{name: 'map', params: {'tab': 'buildings'}}">Budovy</BackButton>
        <ModuleHeader class="pr-3">
            <div id="header-title">
                <h1 v-if="!isNew">Správa budovy</h1>
                <h1 v-else>Přidání budovy</h1>
                <transition name="fade">
                  <v-alert v-if="!isFormValid" type="warning" max-width="600px" class="mt-4 mb-0">
                    Pro <span v-if="isNew">přidání</span><span v-else>úpravu</span> budovy je potřeba: název, popis, geometrie a výchozí podlaží (pokud nějaké podlaží má). Texty musí být v obou jazycích.
                  </v-alert>
                </transition>
            </div>

            <div id="header-actions">
                <v-btn v-if="isUpdating" color="dark" @click="saveChanges" class="ml-5 mb-3 pull-right">Ukládání...</v-btn>
                <v-btn v-else-if="hasRecentlyUpdated" outlined color="dark" @click="saveChanges" class="ml-5 mb-3 pull-right"><v-icon left>check</v-icon> Uloženo</v-btn>
                <v-btn v-else color="primary" @click="saveChanges" class="ml-5 mb-3 pull-right" :disabled="!isFormValid" id="save-btn">Uložit</v-btn>
            </div>
        </ModuleHeader>
        <div class="language-selector ">
              <div class="d-flex align-center">
                <v-tabs
                v-model="currentLanguage"
                    icons-and-text
                    color="light-green darken-1 pull-right"
                >
                    <v-tab :href="'#' + this.languages[0].value" class="small" :key="hasCzAttributesSet + languages[0].value">
                        <div class="d-flex align-center">
                            <country-flag country="cz"/>
                            <p class="mb-0 ml-2">{{this.languages[0].text}}</p>
                            <v-icon v-if="!hasCzAttributesSet" color="red" class="ml-2">fas fa-exclamation-circle</v-icon>
                        </div>

                    </v-tab>
                    <v-tab :href="'#' + languages[1].value" class="small" :key="hasEnAttributesSet + languages[1].value">
                        <div class="d-flex align-center">
                        <country-flag country='gb'/>
                        <p class="mb-0 ml-2">{{this.languages[1].text}}</p>
                        <v-icon v-if="!hasEnAttributesSet" color="red" class="ml-2">fas fa-exclamation-circle</v-icon>
                        </div>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="currentLanguage">
                    <v-tab-item
                    :value="languages[0].value"
                    ></v-tab-item>
                    <v-tab-item
                    :value="languages[1].value"
                    ></v-tab-item>
                </v-tabs-items>
                </div>
            </div>
    </div>
    <div class="row mt-1">

      <div class="col-md-6">
          <v-form @submit.prevent="saveChanges" ref="form">
              <h4 class="mb-3 title">
                Základní informace
              </h4>
            <v-text-field
                id="building-name"
                v-model.trim="singleDetail.localizedAttributes[currentLanguage].name"
                label="Název"
                color="light-green darken-1"
                :rules="[required_rule]"
                @change="madeUnsavedChanges()"
            ></v-text-field>
            <v-textarea
                id="building-description"
                v-model.trim="singleDetail.localizedAttributes[currentLanguage].description"
                label="Popis"
                color="light-green darken-1"
                :rules="[required_rule]"
                @change="madeUnsavedChanges()"
            ></v-textarea>
            <h5 class="mt-2 mb-6">Podlaží</h5>
            <BuildingFloors
                color="light-green darken-1"
                :building="singleDetail"
                :curLng="currentLanguage"
                @input="updateDefaultFloor"
                @onRemoveFloor="removeFloor"
            />
        </v-form>

        <div v-if="!isNew">
          <h4 class="mt-8 mb-3 title">
            Ostatní
          </h4>
          <v-btn text color="error" @click="attemptToDeletePlaceDetail(singleDetail.id)">Smazat budovu</v-btn>
        </div>
      </div>

      <div class="map-component col-md-6" >
          <h4 class="mb-3 title">
              Geometrie
              <v-btn color="primary" text v-on:click="toggleManualDialog()" class="pull-right" id="edit-geometry-btn">Upravit</v-btn>
          </h4>
          <div class="map">
            <MapWidget
              :places="exteriorPlacesAndBuildings"
              :selected="isNew? [] : [`building${singleDetail.id}`, `building${singleDetail.id}-centroid`]"
              :center="singleDetail.outline"
            ></MapWidget>
          </div>

          <geometry-dialog
            :showDialog="this.showManualDialog"
            :position="singleDetail.outline"
            :showCentroid="true"
            :centroidPosition="singleDetail.centroid"
            :allowGeometryTypeChange="false"
            defaultGeometryType="polygon"
            :backgroundObjects="exteriorPlacesAndBuildingsWithoutEditedOne"
            placeType="building"
            :placeName="singleDetail.localizedAttributes.cz.name"
            @updatePosition="updatePosition"
            @close="toggleManualDialog()"
          />
      </div>
    </div>
  </div>
</template>

<script>

    import { mapActions, mapGetters } from 'vuex';
    import { getLocalizedLanguages } from "@/localization/languages";
    import MapWidget from "@/components/map/MapWidget";
    import Loading from "@/components/Loading";
    import BackButton from "@/components/layout/BackButton";
    import ModuleHeader from "@/components/layout/ModuleHeader";
    import BuildingFloors from "@/components/map/BuildingFloors";
    import GeometryDialog from '../../components/map/GeometryDialog.vue';
    import ItemNotFoundState from '../../components/states/ItemNotFoundState.vue';

    export default {
        name: "BuildingDetail",

        components: {
          MapWidget,
          BackButton,
          ModuleHeader,
          BuildingFloors,
          GeometryDialog,
          Loading,
          ItemNotFoundState
        },

        async mounted() {
          await Promise.all([
            await this.fetchFilteredPlaces({floorId: null, buildingId: null, exterior: true, placeName: null, language: null}),
            await this.fetchAllBuildings(),
            await this.fetchAllFloors(),
          ]);

          if (this.isNew){
            this.clear();
            this.prepareNewBuilding();
          } else {
            this.checkIfBuildingExists(this.id);
          }
        },

        watch: {
            //Check for currentLanguage and in case items doesn't have alternatives, inject them
            currentLanguage(curr) {
              if(!this.singleDetail.localizedAttributes[curr]) {
                this.singleDetail.localizedAttributes[curr] = {
                  name: "",
                  description: ""
                };
              }
            },
            hasValidDefaultFloor() {
              if (this.$refs.form) {
                this.$refs.form.validate();
              }
            },
        },

        data() {
          return {
            isUpdating: false,
            hasRecentlyUpdated: false,
            required_rule: value => !!value || 'Povinné',
            currentLanguage: "cz",
            showManualDialog: false,
          }
        },
        props: {
          id: String,
        },

        computed: {
          ...mapGetters('errors', ['objectNotFound']),
          ...mapGetters('floors', ['hasUnsavedFloors', 'getBuildingFloors', 'areFloorsValid', 'areFloorsValidInLanguage']),
          ...mapGetters('buildings', ['getSingleBuilding', 'getEmptyBuilding', 'getBuildings']),
          ...mapGetters('places', ['placesByFloor']),

          singleDetail() {
            if (this.id === "new") {
                return this.getEmptyBuilding;
            } else if (this.getSingleBuilding(this.id)) {
                return this.getSingleBuilding(this.id);
            } else {
                return null;
            }
          },

          hasCentroid() {
            return this.singleDetail.centroid;
          },

          hasPolygon() {
            return this.singleDetail.outline && this.singleDetail.outline.length > 0;
          },

          hasCzAttributesSet(){
            const localized = this.singleDetail.localizedAttributes;
            const czech = this.languages[0].value;

            return (
              localized &&
              localized[czech] &&
              localized[czech].name !== "" &&
              localized[czech].description !== "" &&
              this.areFloorsValidInLanguage(czech) &&
              this.hasValidDefaultFloor
            );
          },

          hasEnAttributesSet(){
            const localized = this.singleDetail.localizedAttributes;
            const english = this.languages[1].value;

            return (
              localized &&
              localized[english] &&
              localized[english].name !== "" &&
              localized[english].description !== "" &&
              this.areFloorsValidInLanguage(english)
            );
          },

          floors() {
              return this.getBuildingFloors(this.singleDetail.id);
          },

          floorIds() {
              return this.floors.map(f => f.id);
          },

          hasValidDefaultFloor(){
            if (!this.singleDetail) { return false; }

            const defaultFloor = this.singleDetail.defaultFloor;

            // a building without floors does not have the default floor
            if (defaultFloor === null && this.floors.length === 0) {
              return true;
            }

            return (
              defaultFloor != null && (this.floorIds.includes(defaultFloor) || this.id === "new")
            );
          },

          isFormValid() {
            return (
              this.hasCzAttributesSet &&
              this.hasEnAttributesSet &&
              this.hasCentroid &&
              this.hasPolygon &&
              this.hasValidDefaultFloor
            );
          },

          languages() {
            const langs = getLocalizedLanguages();
            const selectArr = [];
            Object.keys(langs).map((lng) => {
                selectArr.push({
                  'text': langs[lng],
                  'value': lng,
                });
            });
            return selectArr;
          },

          isNew() {
            return this.id === "new";
          },

          exteriorPlacesAndBuildingsWithoutEditedOne() {
              let results = this.placesByFloor(null).map(p => ({
                  id: `place${p.id}`,
                  type: p.type,
                  position: p.position,
                  name: p.localizedAttributes.cz.name,
              }));

              this.getBuildings
                  .filter(b => b.id !== this.singleDetail.id)
                  .forEach(b => {
                      results.push({
                          id: `building${b.id}`,
                          type: "building",
                          position: b.outline,
                          hideLabel: true,
                      });
                      results.push({
                          id: `building${b.id}-centroid`,
                          type: "building",
                          position: [b.centroid],
                          name: b.localizedAttributes.cz.name,
                      });
                  });

              return results;
          },

          exteriorPlacesAndBuildings() {
              return [
                  ...this.exteriorPlacesAndBuildingsWithoutEditedOne,
                  {
                      id: `building${this.singleDetail.id}`,
                      type: "building",
                      position: this.singleDetail.outline,
                      hideLabel: true,
                  },
                  {
                      id: `building${this.singleDetail.id}-centroid`,
                      type: "building",
                      position: this.singleDetail.centroid ? [this.singleDetail.centroid] : [],
                      name: this.singleDetail.localizedAttributes.cz.name,
                  }
              ];
          },
        },
        methods: {
          ...mapActions('errors', ['clear']),
          ...mapActions('leavePrevent', ['madeUnsavedChanges', 'didSaveChanges']),
          ...mapActions('floors', ['clearFloors', 'createFloor', 'fetchAllFloors']),
          ...mapActions('buildings', ['fetchAllBuildings', 'updateBuilding', 'prepareNewBuilding', 'createBuilding', 'deleteBuilding', 'checkIfBuildingExists']),
          ...mapActions('places', ['fetchFilteredPlaces']),

          updatePosition(outline, centroid) {
            this.singleDetail.centroid = centroid;
            this.singleDetail.outline = outline;
            this.madeUnsavedChanges();
          },

          toggleManualDialog() {
            this.showManualDialog = !this.showManualDialog;
          },

          async finishSaving( success, building={} ) {
            this.isUpdating = false;
            if( success ) {
              this.didSaveChanges();
              this.hasRecentlyUpdated = true;
              setTimeout(() => {
                this.hasRecentlyUpdated = false;
                if( building.id ) this.$router.push({patch:'mapDetail', params:{ type:'building', id: building.id }});
              }, 1000);
            }
          },

          async checkFloorStatus( building ) {
            if( this.hasUnsavedFloors ){
              //First, we assign the building to all the floors
              building.floorIds = [];
              this.floors.forEach(f => f.buildingId = building.id);

              // Then, we create floors on server
              const floorPromise = this.floors.map(async f => {
                const isDefault = this.singleDetail.defaultFloor == f.ghostId;
                const newFloor = await this.createFloor(f);
                if (isDefault) building.defaultFloor = newFloor.id; // update default floor with the new id retrieved from the server
              });
              await Promise.all( floorPromise );

              // Finally, we update the list of building floors with ids received from the server
              this.floors.forEach(f => building.floorIds.push(f.id));
              this.updateBuilding(building);
            }

            this.finishSaving(true, building);
          },

          saveChanges() {
            if(this.$refs.form.validate()){
              this.isUpdating = true;
              let updatedItem = Object.assign({}, this.singleDetail);
              if( this.isNew ) {
                  updatedItem.defaultFloor = null; // Upon creation default floor is name we convert it to id in checkFloorStatus
                  this.createBuilding(updatedItem).then((newBuilding) => {
                    this.singleDetail.id = newBuilding.id;
                    this.checkFloorStatus( newBuilding );
                  }).catch(() => {
                    this.finishSaving(false);
                  });
              } else {
                  this.updateBuilding(updatedItem).then(() => {
                    this.finishSaving(true);
                  }).catch(() => {
                    this.finishSaving(false);
                  });
              }
            }
          },

          async attemptToDeletePlaceDetail( id ) {
              if (window.confirm("Opravdu chcete smazat celou budovu? Smažou se tím i všechny podlaží a místa v dané budově!")) {
                await this.deleteBuilding( id );
                this.$router.push({name: 'map', params: {'tab': 'buildings'}});
              }
          },

          updateDefaultFloor( value ) {
            this.singleDetail.defaultFloor = value;
            this.madeUnsavedChanges();
          },

          removeFloor( id ) {
              const index = this.singleDetail.floorIds.indexOf(id);
              if (this.singleDetail.defaultFloor == id){
                  this.singleDetail.defaultFloor = null;
                  }
              if ( index > -1 ){
                  this.singleDetail.floorIds.splice(index, 1);
              }
          }

        },
      }

</script>

<style scoped>
    .language-selector {
      height: 80px;
    }

    .language-selector > .v-input{
        width: 140px;
    }

    .fade-enter-active, .fade-leave-active {
      transition: opacity 320ms;
    }

    .fade-enter, .fade-leave-to {
      opacity: 0;
    }

    .map-component {
        z-index: 1;
    }

    .map {
        height: 60vh;
    }

    .hidden {
      visibility: hidden;
    }
</style>
